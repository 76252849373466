.App {
  text-align: center;
}

.App-logo {
  height: 2em;
}

body,
div.App,
header.App-header {
  background-color: #e5e5e5;
}

.App-header {
  background-color: #e5e5e5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding-top: 2.5em;
  box-sizing: border-box;
  /* color: #0C0B0B; */
  color: #f5f5f5;
  max-height: 100vh;
  overflow: auto;
}

.App-header p {
  margin: unset;
  margin-bottom: 0.25em;
  text-shadow: 0.0625em 0.0625em 0.0625em #212521cc;
  letter-spacing: 0.05em;
}
.App-header p,
.App-header h3 {
  color: #0c0b0b;
  padding-left: 0em;
}
.App-header h3,
.App-header h4 {
  margin: unset;
  margin-bottom: 0.25em;
  text-shadow: 0.0625em 0.0625em 0.0625em #212521cc;
  letter-spacing: 0.05em;
}

.App-header h4 {
  margin-top: 0.75em;
}

.App-header-bar {
  z-index: 20;
  background-color: #0c0b0b;
  position: absolute;
  height: 3em;
  width: calc(100% - 1.5em);
  top: 0;
  left: 0;
  box-shadow: 0.5em 0.5em #21865b;
  display: flex;
  align-items: center;
  padding: 0.5em;
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
}

.App-header-bar a {
  color: white;
  text-decoration: none;
}
.App-header-bar a:hover {
  text-decoration: underline;
}

.App-header-bar button {
  float: right;
  margin-left: auto;
}

button {
  outline: unset;
  border: unset;
  font-weight: bolder;
}

.App-fondue-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-bar-right {
  float: right;
}

/* App content */
.App-presale {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 0.5em;
  box-sizing: border-box;
  width: 100%;
  margin-top: 3ch;
  position: absolute;
  top: 0;
}
.App-presale-mint {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1em;
  padding-top: 2em;
  box-sizing: border-box;
}

.App-presale-dialog,
.App-presale-info {
  background: rgb(132, 172, 133);
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: calc(100vw - 2em);
  margin-top: 0.5em;
  position: relative;
  padding: 0.5em 0.25em;
  padding-top: 1em;
  color: #0c0b0b;
  margin-bottom: 3em;
}

.App-presale-info {
  margin-top: 1em;
}

.App-presale-total-minted {
  position: absolute;
  width: calc(100%);
  height: 2em;
  top: 0.75em;
  left: 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-presale-total-minted > span {
  font-size: 0.8em;
  z-index: 2;
  text-shadow: 0.0625em 0.0625em 0.0625em #212521cc;
}

.App-presale-total-minted .progress {
  background: #ffe251;
  box-shadow: 0.1em 0.1em 0 0 rgba(255, 255, 255, 0.2);
  height: inherit;
  position: absolute;
  top: -0.05em;
  left: -0.05em;
  width: 100.5%;
}

.input-wrapper {
  position: relative;
  margin: 0.25em 0;
  text-shadow: 0.0625em 0.0625em 0.0625em #212521cc;
  display: inline-block;
  margin: auto;
  margin-bottom: 0.75em;
}
.input-label {
  position: absolute;
  z-index: 4;
  top: 0.325rem;
  right: 0.325rem;
  font-size: 1.5em;
}
.input-title {
  position: absolute;
  top: -1.5ch;
  z-index: 2;
  font-size: 0.6ch;
  color: #0c0b0b;
}
.input-title.open-send {
  transition: color 0.2s ease-in;
}
.input-title.open-send:hover {
  cursor: pointer;
  color: #ffe251;
}
.input-overlay-middle {
  position: absolute;
  top: 1ch;
  left: 0.5ch;
  z-index: 1;
  width: calc(100% - 1em);
  height: calc(100% - 1em);
  color: #0c0b0b;
}
.input-overlay {
  position: absolute;
  right: 0;
  top: 1.4ch;
  z-index: 1;
  margin-right: 0.5ch;
  color: #0C0B0B;
  font-size: 0.75ch;
}
.hover-info {
  position: relative;
  display: inline-block;
}
.i-hover {
  background: #0C0B0B;
  color: white;
  border-radius: 50%;
  font-size: 0.6ch;
  padding: 0.4ch;
  left: unset;
  margin-left: 0.5ch;
  margin-bottom: 1ch;
  position: absolute;
  top: -1.5ch;
}
.info-text {
  display: none;
  margin-top: 0.5ch;
}
.hover-info:hover .info-text {
  display: inline-block;
  position: absolute;
  left: 2.5ch;
  top: -4.5ch;
  background:rgb(91, 182, 107);
  width: 12ch;
  border: 0.5ch #7DD88D solid;
  box-shadow: 0.5ch 0.5ch 0 rgb(33, 134, 91);
  font-size: 0.6ch;
  padding: 0.5ch;
  z-index:10;
}
.i-hover:hover {
  cursor: pointer;
}
.input-label.mice {
  top: 0em;
}
.input-message {
  position: absolute;
  z-index: 4;
  left: 0em;
  bottom: -2em;
  font-size: 0.5em;
  font-weight: bolder;
}

.max-mice {
  position: absolute;
  z-index: 4;
  right: 3rem;
  bottom: 1rem;
  font-size: 0.3em;
  font-weight: bolder;
  width: 10ch;
}

.max-mice > span {
  font-size: 0.7rem;
}
.max-mice > button {
  position: absolute;
  bottom: -0.25em;
  right: 0.3em;
  padding: 1em;
  padding-left: 3.5ch;
}

.mice-input {
  position: relative;
  padding: 0 0.5em;
  /* outline: 5px solid #FFE25133;
  outline-offset: 1px;
  outline-style: groove; */
  border: unset;
  border-radius: 0.15em;
  height: 3em;
  width: calc(100%);
  /* text-align: center; */
  box-sizing: border-box;
  background: #f4fbd7;
  color: #0c0b0b;
  font-weight: bolder;
  font-size: 1em;
}
.clickable *:hover {
  cursor: pointer !important;
}
.mice-input::placeholder {
  color: #939393;
}
.input-message {
  color: #0c0b0b;
}
.prices {
  position: relative;
  display: flex;
  margin: 0ch 1ch;
  box-sizing: border-box;
  margin: auto;
  width: calc(100% - 2.25ch);
  margin-top: 0.75ch;
}
.prices span {
  font-size: 0.75ch;
  color: #0c0b0b;
  font-weight: bolder;
}
.prices span:nth-child(2) {
  margin-left: auto;
}
.mice-balance {
  position: absolute;
  bottom: 0.1ch;
  z-index: 4;
  right: 0.25ch;
  font-size: 0.8em;
}
.arrow-box {
  position: relative;
  height: 0.75em;
  z-index: 4;
}
.arrow-box:hover {
  cursor: default;
}
.arrow-box > span {
  background: #0c0b0b;
  padding: 0.5em;
  z-index: 4;
  font-size: 1.5em;
  margin-left: -1em;
  margin-top: -1.25em;
  position: absolute;
  border-radius: 0.25em;
  box-shadow: 0.1em 0.1em 0 0 rgba(0, 0, 0, 0.5);
}
.mice-input::placeholder {
  color: #939393;

  opacity: 1;
}
.mice-to-keys {
  margin-bottom: 0.25em;
}
.mice-to-keys span {
  font-size: 0.75em;
}
.keys {
  font-size: 0.66em;
  font-weight: bolder;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* media query for phones to make header bar text smaller */
@media screen and (max-width: 600px) {
  .App-header-bar button {
    min-width: 6ch !important;
  }
  .App-header-bar .App-header-bar-account,
  .App-header-bar button div.PixelButton-background {
    font-size: 0.4em;
  }
  .App-presale-info {
    font-size: 0.6em;
    min-width: 100%;
  }
  .PixelBox {
    padding: 1.5ch;
    padding-left: 2ch !important;
    min-width: unset !important;
  }
}

/* DASHBOARD CSS */
.App-dashboard {
  display: grid;
  grid-template-columns: 4fr 3fr 2fr 4fr;
  grid-template-rows: 2fr 1fr 1fr 1fr 1fr;
  height: 100%;
  width: calc(100% - 5ch);
  box-sizing: border-box;
  margin-right: 2.5ch;
  padding-bottom: 5ch;
  padding-top: 1ch;
  grid-gap: 3ch;
  max-width: 1200px;
}



/* media query for above 1k px */
@media screen and (max-width: 1000px) {

  .purchase .timeago {
    font-size: 0.4ch ;
    font-weight: bolder;
  }
  .purchase .amount span {
    font-size: 0.4ch ;
    /* right: 1.5ch !important;*/
    bottom: 1ch ; 
    font-weight: bolder;
  }

  .purchase .buyer.purchase {
    font-size: 0.55ch ;
  }

  .App-dashboard {
    grid-template-columns: 4fr 3fr 3fr;
    grid-template-rows: 30% 20% 10% 20% 10% 20%;
  }

  .App-dashboard-earnings {
    grid-row: 1/3 !important;
    grid-column: 1/2 !important;
    min-height: 22ch;
  }

  .App-dashboard-jackpot {
    grid-row: 1/1 !important;
    grid-column: 2/5 !important;
  }

  .App-dashboard-activity {
    grid-row: 3/6 !important;
    grid-column: 1/2 !important;
  }

  .App-dashboard-buy-keys {
    grid-row: 2/4 !important;
    grid-column: 2/5 !important;
    flex: 1;
  }
  
  .App-dashboard-enter-jackpot {
    grid-row: 4/6 !important;
    grid-column: 2/5 !important;
  }

  #title {
    display: none;
  }

  .App-header-bar-account {
    font-size: 0.8em;
  }
  .App-header-bar .PixelButton-background {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 680px) {

  .App-dashboard {
    grid-template-columns: 100% !important;
    grid-template-rows: unset !important;
  }

  .App-dashboard-earnings {
    grid-row: 5 !important;
    grid-column: 1 !important;
  }

  .App-dashboard-jackpot {
    grid-row: 1 !important;
    grid-column: 1 !important;
    min-height:11ch;
  }

  .App-dashboard-activity {
    grid-row: 6 !important;
    grid-column: 1 !important;
  }

  .App-dashboard-buy-keys {
    grid-row: 2/4 !important;
    grid-column: 1 !important;
    flex: 1;
    min-height:12ch;
  }
  
  .App-dashboard-enter-jackpot {
    grid-row: 4 !important;
    grid-column: 1 !important;
  }

  
}

.App-dashboard .PixelBox {
  width: auto !important;
  min-width: unset !important;
  padding-top: 1.25ch;
}
.PixelBox-content {
  padding-bottom: 8px;
}
.App-dashboard .PixelBox-content h4,
.App-dashboard .PixelBox-content h5 {
  position: relative;
  z-index: 1;
  color: #0c0b0b;
}
.App-dashboard .PixelBox-content h5 {
  font-size: 0.75ch;
}
.App-dashboard .PixelBox-content h5 > span, .App-dashboard .PixelBox-content h4 { 
  font-size: 1ch;
  color:#ffe251;
  text-shadow: 2px 2px 0px #0c0b0b;
}
.App-dashboard .PixelBox-content h4 {
  /* color: white; */
}

.App-dashboard .PixelBox-content h3 {
  position: relative;
  z-index: 1;
}
.App-dashboard .PixelBox-content p {
  position: relative;
  z-index: 1;
  font-size: 0.5ch;
  line-height: 1.25ch;
}
.App-dashboard .PixelBox-content .max-mice {
  right: 1ch;
}
.App-dashboard .PixelBox-content input {
  z-index: 1;
}
.App-dashboard-earnings {
  grid-column: 1/2;
  grid-row: 1/4;
  min-height: 22ch;
}
.App-dashboard-earnings .PixelBox-content {
  display: flex;
  flex-direction: column;
  overflow: inherit;
}
.App-dashboard-earnings .PixelBox-content .PixelButton {
  width: inherit;
  margin-bottom:1ch;
}
.App-dashboard-jackpot {
  grid-column: 2/4;
  grid-row: 1/3;
  min-height: 14ch;

}
.App-dashboard-enter-jackpot {
  grid-column: 2/4;
  grid-row: 3/5;
  min-height: 14ch;
  padding-top: 1.5ch;
}
.App-dashboard-enter-jackpot .PixelBox-content {
  margin-top: 1.5ch;
}
.App-dashboard-enter-jackpot .current-pot-size {
  position: absolute;
  top: 0.7ch;
  left: 0.745ch;
  width: calc(100%);
  height: 1ch;
  z-index: 2;
  padding: 0.25ch 0ch;
  color: #0c0b0b;
}
.App-dashboard-enter-jackpot .current-pot-size .background {
  height: 1.2ch;
  width: inherit;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #ffe251;
  z-index: 1;
}
.App-dashboard-enter-jackpot .current-pot-size span {
  z-index: 2;
  position: relative;
}
.App-dashboard-enter-jackpot .PixelBox-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-dashboard-enter-jackpot .PixelBox-content .mice-balance {
  color: #0c0b0b;
}
.App-dashboard-activity {
  grid-row: 1/5;
  grid-column: 4/6;
}
.App-dashboard-buy-keys {
  grid-column: 1/2;
  grid-row: 4/6;
  min-height: 16ch;
}

.App-dashboard-jackpot .PixelBox-content {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}
.App-dashboard-jackpot .PixelBox-content > * {
  margin: unset;
  flex: auto;
}
.App-dashboard-jackpot .PixelBox-content h3 {
margin-top: 1ch;
}
.App-dashboard-activity .PixelBox-content {
  overflow:hidden;
}
.App-dashboard-activity ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: calc(100% - 1ch);
}
.App-dashboard-activity ul::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 65%, rgb(91, 182, 107) 90%);
}
.App-dashboard-activity ul {
  max-height: 100%;
  overflow: hidden;
}
.App-dashboard-activity ul li {
  color: #0c0b0b;
  position: relative;
  padding: 0.5ch;
  padding-right: 3ch;
  background-color: #7dd88d;
  border: 0.25ch dashed #21865b;
  margin-bottom: 0.5ch;
}

ul li .amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
ul li .amount span {
  font-size: 0.6ch;
  position: absolute;
  right: 0.5ch;
  bottom: 0.5ch;
}
ul li .amount span:nth-child(1) {
  bottom:0.75ch;
  right: 0.75ch;
}
ul li .amount span:nth-child(2) {
  bottom: max(40%, 1.5ch);
  transform: scale(1.5);
  right: 1ch;
  text-shadow: 0.66px 0.66px 0px #0c0b0b;
}
ul li .amount span:nth-child(3) {
  font-size: 0.5ch;
}

li.takeThePot {
  /* background orangered */
  background-color: #ffa500 !important;
  border: 0.25ch dashed #ffe251 !important;

}
.buyer {
  font-size: 0.6ch;
  text-align: left;
  line-height: 1.5ch;
}
.buyer.takeThePot {
  width: max-content;
  max-width: fit-content;
}
.buyer.takeThePot .highlight {
  /* bright redorange color */
  color: #ffe251;
  /* text-shadow: 2px 2px 0px #ffa500; */
}
.buyer span {
  font-size: inherit;
}
.buyer .highlight {
  color: #ffe251;
  text-shadow: 2px 2px #0c0b0b;
}
*:nth-child(1) > span > span {
  letter-spacing: -4px;
}
.timeago {
  font-size: 0.5ch;
  text-align: left;
}

.absolute .PixelBox-content {
  position: absolute;
  top: 0.75ch;
  left: 0.75ch;
  padding: 0.75ch;
  padding-top: 0.25ch;
  width: calc(100% - 1.5ch);
  height: calc(100% - 0.5ch) !important;
  overflow: auto;
  overflow-x: hidden;
}

.PixelBox.App-dashboard-buy-keys .PixelBox-content {
  display: flex;
  flex-direction: column;
  /* justify-self: center; */
  align-items: center;
}

.balances {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 2ch);
  margin-top: 1ch;
}

.balances span {
  font-size: 0.66ch;
  color: #0c0b0b;
  font-weight: bold;
}

.PixelBox.App-dashboard-buy-keys .PixelBox-content .PixelButton {
  width: calc(100% - 2ch);
  height: 2ch;
  margin: auto
}

.popup-modal {
  /* cover screen and place above everything */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 20;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-modal .mice-input {
  width: calc(100% - 2ch);
  height: 2.5ch;
  margin: auto;
  font-size: 0.6ch;
  color: #0c0b0b;
  border: 0.25ch solid #0c0b0b;
  border-radius: 0.25ch;
  padding: 0.25ch;
}

.popup-modal-input { 
  margin-top: 0.5ch;
}

.popup-modal-input label {
  font-size: 0.66ch;
  color: #0c0b0b;
  position: relative;
  z-index: 2;
  text-align: left;
  
}

.popup-modal-button {
  margin-top: 1ch;
}

.popup-modal .popup-modal-content span  {
  position: relative;
  z-index: 10;
}
.popup-modal-content {
  max-width: 30ch;
}

.popup-modal-button {
  display: flex;
  justify-content: column;
}

.send-keys {
  height: 2ch;
  width: 80%;
  margin: auto;
}

.popup-modal .popup-modal-content span.close {
  position: absolute;
  top: 0.33ch;
  left: 0.33ch;
  color: #0c0b0b;
  font-weight: bolder;
  transition: color 0.2s ease-in-out;
}
.popup-modal .popup-modal-content span.close:hover {
  color: #ffe251;
  cursor: pointer;
}

.popup-modal .popup-modal-content {
  padding: 1ch;
}

.popup-modal .PixelBox-top-left-px {
  opacity: 0;
}
.popup-modal .PixelBox-bottom-right-px {
  opacity: 0;
}