.App-dashboard-migration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    height:16ch;
}

.App-dashboard-migration-success {
    height:13ch;
}

.App-dashboard-migration h3 {
    margin-top: 2em;
}

.App-dashboard-migration p {
    font-size: 0.65em;
    margin-top: 1em;
    font-weight: lighter;
    line-height: 1.5em;
}

.App-dashboard-migration p span {
    color: #ffe251;
    font-size: 1em;
}
.App-dashboard-migration .App-dashboard-migration-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;
    width: 100%;
}
.App-dashboard-migration-buttons .short-and-long {
    min-height: 2ch;
    width: 90%;
}